<template>
  <!-- 添加最外层容器 -->
  <div class="page-container">
    <!-- 添加顶部导航栏 -->
    <NavigationBar title="联系客服"/>

    <div class="guide-container">
      <div class="content">
        <!-- 图标或图片 -->
        <text style="font-size: 40px">
          请先登录或者注册账户
        </text>

        <!-- 按钮组 -->
        <div class="button-group">
          <van-button
              type="primary"
              round
              block
              @click="goToRegister"
              class="register-btn"
          >
            注册账户
          </van-button>

          <van-button
              type="default"
              round
              block
              @click="goToLogin"
              class="login-btn"
          >
            立即登录
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/views/NavigationBar.vue";

export default {
  name: 'LoginGuide',
  components: {NavigationBar},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    goToRegister() {
      this.$router.push('/register');
    },
    goToLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
/* 添加最外层容器样式 */
.page-container {
  min-height: 100vh;
  background-color: #fff;
}

.guide-container {
  min-height: calc(100vh - 46px); /* 减去导航栏的高度 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  /* 添加顶部边距，避免被导航栏遮挡 */
  padding-top: 66px; /* 46px导航栏高度 + 20px原有padding */
}

.content {
  text-align: center;
}

.button-group {
  margin-top: 40px;
  padding: 0 20px;
}

.register-btn {
  margin-bottom: 15px;
}

.login-btn {
  border: 1px solid #1989fa;
  color: #1989fa;
}

/* 自定义空状态组件的样式 */
:deep(.van-empty) {
  padding: 40px 0;
}

:deep(.van-empty__description) {
  font-size: 16px;
  color: #666;
  margin-top: 20px;
}

/* 添加导航栏样式 */
:deep(.van-nav-bar) {
  background-color: #fff;
}

:deep(.van-nav-bar__title) {
  color: #323233;
  font-size: 16px;
}

:deep(.van-nav-bar .van-icon) {
  color: #323233;
}
</style>
